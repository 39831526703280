
// Slug: 5ob9aj

// ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------- //

window.m5ob9aj = {
	AutoSubmission
};

function AutoSubmission() {

	document.querySelector( ".logout-form" ).submit();

}
