const _temp0 = require("../cfml-main/views/error/error.view.js");

const _temp1 = require("../cfml-main/views/auth/common/layout.view.js");

const _temp2 = require("../cfml-main/views/auth/login/form/form.view.js");


const _temp3 = require("../cfml-main/views/auth/logout/form/form.view.js");



const _temp4 = require("../cfml-main/views/playground/common/layout.view.js");

const _temp5 = require("../cfml-main/views/playground/features/list/list.view.js");

const _temp6 = require("../cfml-main/views/playground/features/detail/defaultResolution/defaultResolution.view.js");

const _temp7 = require("../cfml-main/views/playground/features/detail/rule/rule.view.js");

const _temp8 = require("../cfml-main/views/playground/features/detail/targeting/targeting.view.js");



const _temp9 = require("../cfml-main/views/playground/raw/json/json.view.js");


const _temp10 = require("../cfml-main/views/playground/staging/matrix/matrix.view.js");

const _temp11 = require("../cfml-main/views/playground/staging/user/user.view.js");


const _temp12 = require("../cfml-main/views/playground/users/list/list.view.js");


const _temp13 = require("../cfml-main/views/playground/videos/list/list.view.js");


const _temp14 = require("../cfml-main/views/playground/walkthrough/common/layout.view.js");
const _temp15 = require("../cfml-main/views/playground/walkthrough/common/raw.view.js");
const _temp16 = require("../cfml-main/views/playground/walkthrough/common/targeting.view.js");

const _temp17 = require("../cfml-main/views/playground/walkthrough/step6/step6.view.js");



module.exports = {
  "error":   {
    "error": _temp0
  },
  "auth":   {
    "common":     {
      "layout": _temp1
    },
    "login":     {
      "form":       {
        "form": _temp2
      }
    },
    "logout":     {
      "form":       {
        "form": _temp3
      }
    }
  },
  "playground":   {
    "common":     {
      "layout": _temp4
    },
    "features":     {
      "list":       {
        "list": _temp5
      },
      "detail":       {
        "defaultResolution":         {
          "defaultResolution": _temp6
        },
        "rule":         {
          "rule": _temp7
        },
        "targeting":         {
          "targeting": _temp8
        }
      }
    },
    "raw":     {
      "json":       {
        "json": _temp9
      }
    },
    "staging":     {
      "matrix":       {
        "matrix": _temp10
      },
      "user":       {
        "user": _temp11
      }
    },
    "users":     {
      "list":       {
        "list": _temp12
      }
    },
    "videos":     {
      "list":       {
        "list": _temp13
      }
    },
    "walkthrough":     {
      "common":       {
        "layout": _temp14,
        "raw": _temp15,
        "targeting": _temp16
      },
      "step6":       {
        "step6": _temp17
      }
    }
  }
}